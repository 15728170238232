@mixin container {
    position: relative;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;

    // @include breakpoint(m) {
    //     max-width: map-fetch($containers, m);
    // }

    @include breakpoint(l) {
        max-width: map-fetch($containers, l);
    }

    @include breakpoint(xl) {
        max-width: map-fetch($containers, xl);
        // padding: 0;
    }

    @include breakpoint(xxl) {
        max-width: map-fetch($containers, xxl);
    }
}
