.episodes__sidenav {
    display: none;

    @include breakpoint(l) {
        display: flex;
        position: absolute;
        top: 475px;
        right: 20px;
        margin-right: 20px;
        text-align: center;
        flex-direction: column;
        align-items: center;
        color: color($color-blue);
        z-index: z-index(sidenav);
    }

    &-heading {
        display: block;
        font-size: .8rem;
        text-transform: uppercase;
        font-family: $font-family-sans-serif;
        margin-bottom: .5rem;
    }

    &-ruler {
        height: 2.5rem;
        background-color: color($color-blue);
        width: 2px;
    }

    &-label {
        position: relative;
        z-index: 1;
    }

    &-index {
        position: relative;
        font-family: $font-family-headings;
        display: block;
        font-size: .75rem;
        height: 1.75rem;
        width: 1.5rem;
        border: 0 solid transparent;
        padding: .5rem;
        line-height: 1;
        color: inherit;
        text-decoration: none;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: .8rem;
        }

        &::before {
            background: color($color-white);
        }

        &::after {
            background: color($color-blue);
            transform: scale(0);
            transition: transform .2s ease-in-out;
        }

        &.active {
            color: color($color-white);
            pointer-events: none;

            &::after {
                transform: scale(1);
            }
        }

        @include hover {
            font-weight: bold;
        }
    }
}
