.home {
    // @include breakpoint(l) {
    //     min-height: 80vh;
    //     display: flex;
    //     align-items: center;
    // }

    &__container {
        // opacity: 0;
        // transform: translateY(100px);
        // transition: transform 1s ease-in-out, opacity 1s ease-in-out;

        // .home.in & {
        //     opacity: 1;
        //     transform: translateY(0);
        // }

        @include breakpoint(l) {
            display: flex;
            flex-direction: row-reverse;
        }
    }

    &__heading {
        @include letter-spacing(xl);
        color: color($color-blue, medium);
    }

    &__chapeau {
        color: color($color-blue, base);
    }

    &__visual {
        position: relative;
        // height: 11rem;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 40%);
        }

        @include breakpoint(l) {
            padding: 4rem 0 1rem;
        }
    }

    &__visuals {
        padding: 1rem 2rem 0;

        .home__image {
            width: 100%;
        }

        @include breakpoint(l) {
            // opacity: 0;
            // transform: translateY(100px);
            transition: transform 2s ease-in-out, opacity 2s ease-in-out;
            display: flex;
            flex-direction: column;
            padding: 0;

            // .home.in & {
            //     opacity: 1;
            //     transform: translateY(0);
            // }
        }
    }

    &__content {
        position: relative;
        background: color($color-white, base);
        margin: -2rem 1rem 0;
        padding: 1rem 1rem 2rem;

        &::after {
            content: '';
            position: absolute;
            left: 1rem;
            right: 1rem;
            bottom: 0;
            opacity: .2;
            border-bottom: 1px solid color($color-gray, base);
        }

        @include breakpoint(l) {
            padding: 4rem 0 0 4rem;
            margin: 0;
            background: transparent;
            width: col(6);

            &::after {
                display: none;
            }
        }
    }

    &__body {
        @include breakpoint(l) {
            padding-top: 1.25rem;
            width: col(8);
        }
    }

    // &__image {
    //     object-fit: cover;
    //     object-position: 100% 100%;
    //     width: 100%;
    //     height: 100%;
    // }

    .button {
        margin-top: .5rem;

        @include breakpoint(l) {
            margin-bottom: 2.5rem;
        }
    }

    &__readmore {
        position: relative;
        text-align: center;
        margin-top: 3rem;

        @include breakpoint-upto(l) {
            padding-bottom: 4rem;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 65%;
            background: color($color-gray, light);
        }

        h2 {
            font-size: 24px;
            color: color($color-gray);
        }

        img {
            max-width: 440px;
            margin: 0 auto 1rem;
        }
    }
}

.awards {
    &__images {
        // display: grid;
        // grid-template-columns: auto auto auto auto auto;
        // grid-column-gap: 35px;
        margin: -1rem -17px 2rem;

        @include breakpoint(l) {
            margin-bottom: 4rem;
        }

        img {
            display: inline-block;
            height: 40px;
            vertical-align: middle;
            margin: 17px;

            @include breakpoint(xl) {
                height: 50px;
            }
        }
    }
}
