.language {
    position: relative;
    font-family: $font-family-headings;
    text-transform: uppercase;
    user-select: none;
    z-index: z-index(language);

    &__current {
        color: $primary-color;
        padding: 0 0 0 .5rem;
        cursor: pointer;

        .icon {
            transition: transform .2s ease-in-out;
        }
    }

    &__selector {
        @include list-unstyled;
        position: absolute;
        background: color($color-white);
        line-height: $nav-height-sm;
        overflow: hidden;
        max-height: 0;
        transform-origin: 0 0;
        will-change: max-height;
        transition: .2s max-height ease-in-out;
        margin-top: .5rem;
        margin-left: -.5rem;
    }

    &__item {
        line-height: 2;
    }

    &__link {
        text-decoration: none;
        color: $text-color;
        padding: 0 1rem;

        @include hover {
            color: color($color-blue);
        }
    }

    &__checkbox {
        display: none;

        &:checked ~ .language__selector {
            max-height: 130px;
        }

        &:checked ~ .language__current .icon {
            transform: rotate(180deg);
        }
    }

    &__icon {
        @include icon(xs);
        margin-left: 2px;
    }
}
