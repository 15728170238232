.slider {
    @include container;

    &__list {
        @include list-unstyled;
        position: relative;
        width: 100%;
        height: 260px;

        @include breakpoint(l) {
            height: 500px;
        }
    }

    &__item {
        position: absolute;
        top: 0;
        left: 10%;
        width: 80%;
        height: 100%;
        display: none;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        transition: transform .8s ease-in-out;

        &:not(.is-active) {
            transform: translateX(100%);

            @include breakpoint(l) {
                transform: translateX(calc(100% + 1rem));
            }

            &.is-prevprev,
            &.is-prev {
                transform: translateX(-100%);

                @include breakpoint(l) {
                    transform: translateX(calc(-100% - 1rem));
                }
            }
        }

        &.is-prevprev,
        &.is-prev,
        &.is-active,
        &.is-next {
            display: block;
        }

        &.is-prev,
        &.is-next {
            z-index: 1;
        }

        &.is-active {
            z-index: 2;
        }
    }

    &__heading {
        position: absolute;
        color: color($color-white);
    }

    &__dots {
        @include list-unstyled;
        margin: 1rem 0 0;
    }

    &__dot {
        width: 6px;
        height: 6px;
        display: inline-block;
        background: color($color-gray);
        border-radius: 50%;
        transition: background-color .2s linear, transform .2s ease-in-out;
        cursor: pointer;
        margin: 0 .25rem;

        &.active {
            background: color($color-blue);
            transform: scale(1.3);
        }

        .slider-label {
            display: none;
        }
    }
}

.slide {
    &__background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &-image {
            object-fit: cover;
            width: 100%;
            height: 100%;
            object-position: 50% 50%;
            transition: transform .5s ease-in-out;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: .8;
            background: color($color-black);
            z-index: 1;
            transition: opacity .3s linear;
        }
    }

    &.is-active {
        .slide__background::before {
            opacity: .6;
        }

        &:hover {
            .slide__background::before {
                opacity: .4;
            }

            .slide__background-image {
                transform: scale3d(1.05, 1.05, 1.05);
            }
        }
    }

    &__content,
    &__link {
        position: relative;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        z-index: 2;
    }

    &__link {
        text-decoration: none;

        .button {
            color: color($color-white);
        }
    }

    &__heading {
        color: color($color-white);
        text-transform: uppercase;
        font-family: $font-family-headings;
        font-size: 2rem;
        margin: 0;
        width: 90%;
        max-width: 735px;
        margin-bottom: .5rem;

        @include breakpoint(l) {
            font-size: 5rem;
        }
    }
}
