.footer {
    position: relative;
    background-color: color($color-white);
    color: color($color-blue);
    text-align: center;
    padding-top: 3rem;
    font-size: .7rem;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: .75rem;
        background: color($color-white);
        top: -.75rem;
        left: 0;
    }

    &__container {
        padding: 2rem 0;
        border-top: 1px solid color($color-gray, light);
    }

    &__items {
        @include list-unstyled;
    }

    &__item {
        position: relative;
        display: inline-block;
        padding: 0 .5rem;

        &::before {
            content: '';
            position: absolute;
            width: 1px;
            height: 70%;
            background: color($color-blue);
            left: 0;
            top: 20%;
        }

        &:first-child::before {
            display: none;
        }
    }

    &__link {
        color: color($color-blue);
    }
}
