.modal {
    opacity: 0;
    pointer-events: none;
    z-index: -1;

    iframe {
        pointer-events: none;
        display: none;
        margin: 1rem auto;
    }

    &.active {
        position: fixed;
        z-index: z-index(modal);
        pointer-events: auto;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: color($color-blue, transparent);
        opacity: 1;
        padding: 1.5rem;
        transition: opacity .5s ease-in-out;

        iframe {
            display: block;
            pointer-events: auto;
            width: 100%;
            border: 0px none;
        }
    }

    &__inner {
        background: color($color-white);
        position: absolute;
        top: 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        padding: 2rem 0 1rem;
        overflow: hidden;
        transform: translateY(100px);

        @include breakpoint(l) {
            top: 50%;
            left: 50%;
            right: auto;
            bottom: auto;
            width: 80%;
            height: 80%;
            max-width: 1000px;
            max-height: 800px;
            transform: translate(-50%, calc(-50% + 100px));
        }

        &::before {
            content: '';
            position: absolute;
            background: color($color-blue);
            width: 100%;
            height: 100%;
            pointer-events: none;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            opacity: 0;
        }

        .modal.active & {
            transform: translateY(0);
            transition: transform .5s ease-in-out;

            @include breakpoint(l) {
                transform: translate(-50%, -50%);
            }

            &::before {
                opacity: 1;
                transition: transform .8s ease-out .7s;
                transform: translateY(-100%);
            }
        }
    }

    &.modal-share,
    &.modal-subscriptions {
        @include breakpoint(l) {
            .modal__inner {
                height: auto;
            }
        }
    }

    &.modal-share {
        .modal__inner {
            max-width: 650px;
        }
    }

    &__content {
        margin: 1rem auto;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        padding: 0 1rem;
        height: 100%;

        @include breakpoint(l) {
            margin: 2rem auto;
            height: calc(100% - 1rem);
            padding: 0 4rem 2rem;
        }
    }

    &__header {
        background: color($color-gray);
        position: fixed;
        width: 100%;
    }

    &__close {
        position: fixed;
        z-index: 1;
        color: color($color-blue);
        right: 0;
        top: .7rem;
        cursor: pointer;
        transform: scale(0);
        will-change: transform;
        transition: transform .2s ease-in-out;

        @include breakpoint(l) {
            right: 1.2rem;
            top: 1.8rem;
        }

        &.active {
            transform: scale(1);
        }

        @include hover {
            transform: scale(1.1);
            transition-delay: 0;
        }
    }

    &__chapeau {
        @include letter-spacing(xxl);
        position: relative;
        text-align: center;
        text-transform: uppercase;
        color: color($color-blue, medium);
        font-family: $font-family-headings;
        margin-bottom: 1.6rem;

        @include breakpoint(l) {
            font-size: 1.5rem;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -15px;
            left: 50%;
            width: 34px;
            height: 2px;
            background: color($color-blue);
            transform: translateX(-50%);
        }
    }

    &__heading {
        text-align: center;
        color: color($color-blue, medium);
        margin-bottom: 1.75rem;

        @include breakpoint(l) {
            font-size: 2rem;
        }
    }

    &__note {
        font-style: italic;
        font-size: .8rem;
        line-height: 1.5;
        margin-bottom: 2rem;

        strong {
            color: color($color-blue, dark);
        }
    }

    &__block {
        &-text {
            @include breakpoint(l) {
                width: 80%;
                margin: 0 auto;
            }
        }

        &-figure {
            margin-bottom: 1rem;
        }

        &-img {
            margin: 0 auto;
        }

        // &-img {
        //     @include breakpoint(l) {
        //         max-width: none;
        //         width: 120%;
        //         transform: translateX(-10%);
        //     }
        // }

        &-figcaption {
            position: relative;
            font-style: italic;
            font-size: .6rem;
            line-height: 1.25;
            padding-left: 1.5rem;
            margin-top: .5rem;

            @include breakpoint(l) {
                font-size: .8rem;
            }

            &::before {
                content: '';
                position: absolute;
                top: .25rem;
                left: 0;
                width: 1rem;
                height: 2px;
                background: color($color-blue);

                @include breakpoint(l) {
                    top: .5rem;
                }
            }
        }

        &-heading {
            text-align: center;
            text-transform: uppercase;
            color: color($color-blue, medium);
            font-family: $font-family-headings;
        }
    }

    &.video-modal {
        .modal__inner {
            width: calc(100% - 2rem);
            height: calc(100% - 2rem);
            max-width: none;
            max-height: none;
        }

        .modal__content {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
