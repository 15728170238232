.ifly {
    padding-top: 2rem;
    text-align: center;
    position: relative;
    z-index: 1;
    margin-bottom: 2rem;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: .75rem;
        background: color($color-gray, light);
    }

    @include breakpoint(l) {
        padding-top: 7rem;
        margin-bottom: 0;
        // margin-top: 9rem;
    }

    &__heading {
        color: color($color-blue, medium);
    }

    &__chapeau {
        color: color($color-blue, base);
    }

    &__intro {
        position: relative;
        padding: 0 1rem 2rem;

        @include breakpoint(l) {
            width: col(7);
            margin: 0 auto 5rem;
            padding: 0 0 2rem;
        }

        &::after {
            content: '';
            position: absolute;
            width: 34px;
            height: 2px;
            background: color($color-blue, dark);
            margin-top: .5rem;
            transform: translateX(-50%);

            @include breakpoint(l) {
                width: 76px;
                margin-top: 2rem;
            }
        }
    }

    &__button {
        margin-top: 2rem;
        position: relative;

        .icon {
            transform: rotate(-90deg);
        }
    }
}
