@mixin list-unstyled {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

@mixin touchscroll {
    -webkit-overflow-scrolling: touch;
}

@mixin bg-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

@mixin button-reset {
    appearance: none;
    border: 0;
    background: 0;

    &:focus {
        outline: 0;
    }
}

@mixin font-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin letter-spacing($key) {
    letter-spacing: convert-letter-spacing(map-fetch($letter-spacing, $key));
}
