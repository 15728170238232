// breakpoint mixin for easier media queries
@mixin bp($width) {
    @media only screen and (min-width: $width) {
        @content;
    }
}

// or by key (s, m, l, etc)
@mixin breakpoint($key) {
    @include bp(map-fetch($breakpoints, $key)) {
        @content;
    }
}

@mixin bp-max($width) {
    @media only screen and (max-width: $width - 1px) {
        @content;
    }
}

@mixin breakpoint-upto($key) {
    @include bp-max(map-fetch($breakpoints, $key)) {
        @content;
    }
}

// height breakpoints
@mixin bph($height) {
    @media only screen and (min-height: $height) {
        @content;
    }
}

@mixin bph-max($height) {
    @media only screen and (max-height: $height) {
        @content;
    }
}
