.episode {
    @include letter-spacing(xxl);
    position: relative;
    text-align: center;
    height: auto;

    &__container {
        @include breakpoint(l) {
            display: flex;
        }
    }

    &__chapeau {
        @include letter-spacing(l);
        color: color($color-blue, medium);

        @include breakpoint(l) {
            padding: 0;

            #episode-0 & {
                color: color($color-white);
            }
        }
    }

    &__headings {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: z-index(episode-headings);

        #episode-0 & {
            @include breakpoint(l) {
                height: calc(100% - 200px);
            }
        }
    }

    &__heading {
        position: relative;
        color: color($color-white);
        margin: 0 auto;
        width: 80%;
        font-size: 1.5rem;

        @include breakpoint-upto(m) {
            font-size: 1rem;
            width: 100%;
        }
    }

    &__heading-line {
        width: 30px;
        height: 2px;
        background: color($color-blue);
        border: 0;
        margin: .5rem auto;
    }

    &__heading-sub {
        font-size: .75rem;
        color: color($color-white);
        margin: 0;

        @include breakpoint(l) {
            font-size: .9rem;
        }
    }

    &__visual {
        position: relative;
        padding: 0 2rem;

        @include breakpoint(l) {
            padding: 0;
            overflow: hidden;

            #episode-1 & {
                margin-bottom: 3.5rem;
            }
        }

        &::before,
        &::after {
            content: '';
            position: absolute;
            pointer-events: none;
            right: 0;
            top: 0;
            border-top: 1px solid color($color-gray, light);

            @include breakpoint(l) {
                display: none;
            }
        }

        &::before {
            top: 0;
            left: 2rem;
        }

        &::after {
            top: 2rem;
            width: 2rem;
        }

        &-background {
            position: absolute;
            width: 50%;
            height: 0;
            // padding-bottom: calc(100% - 2rem);
            top: 0;
            left: 0;
            overflow: hidden;
            z-index: 1;

            @include breakpoint(l) {
                position: absolute;
                padding: 0;
                width: 50%;
                height: 100%;
                right: 50%;
                left: auto;
                transform: translateX(-6rem);

                &::after,
                &::before {
                    content: '';
                    position: absolute;
                    left: 6rem;
                    top: 0;
                    width: 368px;
                    height: 100%;
                    z-index: 1;
                }

                &::after {
                    background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                }

                &::before {
                    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
                }
            }

            &-image {
                position: absolute;
                right: -100%;
                top: 0;
                width: auto;
                max-width: none;
                opacity: .3;

                @include breakpoint(l) {
                    height: 100%;
                    right: -2rem;
                    top: -6rem;
                }
            }

            &-right {
                position: absolute;
                height: 100%;
                width: 100%;
                overflow: hidden;
                left: 0;
                top: 0;

                @include breakpoint-upto(l) {
                    display: none;
                }

                img {
                    height: calc(100% + 8rem);
                    width: auto;
                    max-width: none;
                    position: absolute;
                    top: -4rem;
                    right: -6rem;
                    transform: translate(-2rem, -2rem);

                    @include breakpoint(xl) {
                        right: -4rem;
                    }
                }
            }
        }

        &-content {
            position: relative;
            background: color($color-gray);
            width: 100%;
            overflow: hidden;
            z-index: 1;
            // height: 30px;

            @include breakpoint(l) {
                width: calc(40% - 4rem);
                margin-left: 10%;
            }

            @include breakpoint-upto(l) {
                height: calc(50vw - 40px);
            }
        }

        &-image {
            position: absolute;
            height: calc(200% + 7rem);
            top: 50%;
            left: 50%;
            right: auto;
            // height: auto;
            max-width: none;
            transform: translate(-50%, -50%);
            margin: -2rem 0 0 -.5rem;
            pointer-events: none;

            @include breakpoint(l) {
                top: -4rem;
                left: auto;
                right: -2rem;
                height: calc(100% + 8rem);
                transform: translate(-2rem, -2rem);
                margin: 0;
            }

            &.foreground {
                z-index: z-index(episode-foreground);

                @include breakpoint-upto(l) {
                    height: 150%;
                    top: -50%;
                    left: 0;
                    transform: none;
                    margin: 0;
                }

                @include breakpoint(l) {
                    #episode-0 & {
                        left: -.75rem;
                    }
                }
            }

            &.overlay {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                height: calc(200% + 11rem);
                right: auto;
                margin: -2rem 0 0 -.5rem;

                @include breakpoint(l) {
                    height: calc(100% + 11rem);
                    margin: -2rem 0 0 -4rem;
                }

                #episode-0 & {
                    // margin: 3rem 0 0;

                    @include breakpoint(l) {
                        margin: 3rem 0 0 -7rem;
                    }
                }
            }

            #episode-0 & {
                @include breakpoint(l) {
                    right: -4rem;
                }
            }
        }

        &-overlay {
            position: absolute;
            z-index: z-index(episode-overlay);
            top: 30px;
            left: 30px;
            width: calc(100% - 60px);
            height: calc(100% - 60px);
            overflow: hidden;
            transform-origin: 0 0;

            @include breakpoint-upto(l) {
                top: 20px;
                left: 20px;
                width: calc(100% - 40px);
                height: calc(100% - 40px);
            }
        }
    }

    &__content {
        position: relative;
        padding: 1rem 0;
        text-align: left;
        font-size: .9rem;
        display: flex;
        flex-direction: column;

        @include breakpoint(l) {
            // width: calc(50% - 2rem);
            width: 50%;
            padding: 0 0 0 2rem;
            justify-content: center;

            h4 {
                font-size: 1rem;
            }

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 2rem;
                bottom: 0;
                background-color: color($color-white, transparent);
                // border-right: 1px solid color($color-gray, light);
                // border-top: 1px solid color($color-gray, light);
            }

            #episode-0 & {
                color: color($color-white);
                z-index: 1;

                &::before {
                    display: none;
                }

                a {
                    color: color($color-white);
                }
            }
        }


        &-inner {
            position: relative;

            #episode-0 & {
                @include breakpoint(l) {
                    padding-bottom: 200px;
                }
            }
        }
    }

    &__intro {
        font-size: .7rem;

        p {
            margin-bottom: .5rem;
        }

        @include breakpoint(l) {
            margin-top: -.5rem;
        }

        @include breakpoint(xl) {
            font-size: .8rem;
        }
    }

    &__player {
        height: 52px;
        margin: 0 -.5rem .5rem;
        width: 100%;

        iframe {
            width: calc(100% + 1rem);
            height: 100%;
            border: 0px none;
            overflow: hidden;
        }

        @include breakpoint(l) {
            margin-top: 1rem;
        }
    }

    &__buttons {
        margin: .5rem 0 1rem;
        display: flex;
        justify-content: space-between;

        .button {
            font-size: .65rem;
            white-space: nowrap;

            @include breakpoint-upto(s) {
                margin-left: 10px;

                &:first-child {
                    margin-left: 0;

                    .icon {
                        display: none;
                    }
                }
            }
        }
    }

    &__header {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin: 0 0 -6rem;

        @include breakpoint(l) {
            margin: -6rem 0 -12%;
        }

        // &::after {
        //     content: '';
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        //     width: 50%;
        //     height: 6rem;
        //     background: color($color-white);
        //     opacity: .9;
        // }

        &-image {
            height: 15rem;
            max-width: none;
            transform: translateX(-30%);

            @include breakpoint(m) {
                max-width: 100%;
                height: auto;
                transform: none;
            }
        }
    }

    &__unpublished {
        position: relative;
        margin-top: .5rem;
        padding-top: 1rem;
        font-weight: bold;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 137px;
            height: 1px;
            background: color($color-gray, light);
        }

        &-date {
            font-size: .7rem;
            color: color($color-blue, medium);

            @include breakpoint(xl) {
                font-size: .8rem;
            }
        }
    }

    &.has-header {
        @include breakpoint(l) {
            margin-bottom: -40px;
        }

        .episode__visual {
            padding-top: 0;

            &::before,
            &::after {
                display: none;
            }

            &-background {
                top: -2rem;

                @include breakpoint(l) {
                    top: 0;

                    &-image {
                        top: -2rem;
                    }
                }
            }
        }

        .episode__intro {
            @include breakpoint(l) {
                border-top: 0;
                margin-top: -.5rem;
            }
        }

        .episode__content {
            padding-top: 1rem;
        }
    }

    @include breakpoint-upto(l) {
        &__player {
            order: 1;
        }

        &__chapeau {
            order: 2;
        }

        &__intro {
            order: 3;
        }

        &__buttons,
        &__unpublished,
        &__mail {
            order: 4;
        }
    }

    @include breakpoint(l) {
        // &__player,
        // &__chapeau,
        // &__intro,
        // &__buttons,
        // &__unpublished,
        // &__mail {
        //     z-index: 1;
        // }

        &__chapeau,
        &__unpublished,
        &__player,
        &__intro,
        &__buttons {
            width: calc(100% - 4rem);
        }
    }
}
