html {
    @include font-smoothing;
    font-size: 19px;
    font-family: $font-family-serif;
    color: $text-color;
}

body {
    font-size: .7rem;

    @include breakpoint(l) {
        font-size: .8rem;
    }
}

h1,
h2,
h3,
h4 {
    font-family: $font-family-sans-serif;
    font-weight: 100;
    color: $primary-color;
}

h1 {
    font-size: 2.625rem;
    font-weight: bold;

    @include breakpoint(l) {
        font-size: 5.25rem;
    }

    span {
        @include letter-spacing(s);
        display: block;
        font-size: 2rem;
        font-weight: normal;
        text-transform: lowercase;
    }
}

h2 {
    font-size: 1.25rem;

    @include breakpoint(l) {
        font-size: 2.5rem;
    }
}

h3 {
    font-size: 1.25rem;

    @include breakpoint(l) {
        font-size: 2rem;
    }
}

h4 {
    font-size: 1rem;
    line-height: 1.4;

    @include breakpoint(xl) {
        font-size: 1.25rem;
    }
}

h5 {
    font-family: $font-family-headings;
    font-size: 1rem;
    font-weight: 100;

    @include breakpoint(l) {
        font-size: 1.4rem;
    }
}

p {
    @include letter-spacing(s);
}
