.nav {
    position: fixed;
    top: 0;
    left: 0;
    height: $nav-height-sm;
    width: 100%;
    background: color($color-white);
    z-index: z-index(nav);

    @include breakpoint(l) {
        height: $nav-height;
    }

    .container {
        padding: 0 1rem;

        @include breakpoint(l) {
            padding: 0 .5rem;
        }

        @include breakpoint(xl) {
            padding: 0;
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: $nav-height-sm;

        @include breakpoint(m) {
            height: $nav-height;
            justify-content: space-between;
        }
    }

    &__logo {
        position: absolute;
        top: .25rem;
        left: 1rem;
        color: $primary-color;

        @include breakpoint(m) {
            position: relative;
            margin-left: -1rem;
            margin-top: -.75rem;
        }

        &-icon {
            @include icon(xxl);
        }
    }
}
