.intro {
    position: relative;
    z-index: z-index(intro);
    width: 100%;
    height: 80vh;
    background-color: color($color-white);
    color: color($color-white);
    text-align: center;
    transform: translateY(0);
    transition: transform 1s ease-in-out;
    overflow: hidden;

    &__container {
        position: relative;
        z-index: 3;
        margin-top: $nav-height * 1.5;
        height: calc(65% - 3.75rem);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        @include breakpoint-upto(m) {
            height: calc(80% - 3.125rem);
        }
    }


    &__heading {
        display: none;
        // @include letter-spacing(xs);
        // color: color($color-white);
        // margin-top: 2.5rem;
        //
        // span {
        //     @include letter-spacing(s);
        // }
    }

    &__subheading {
        display: none;
        // @include letter-spacing(xxl);
        // color: color($color-white);
    }

    &__content {
        // position: absolute;
        // width: 100%;
        // left: 0;
        // top: 50%;
        // transform: translate(0, -50%);
        opacity: 0;
        padding: 0 1rem 1rem;

        p {
            @include letter-spacing(xl);
            margin: 0 auto 1rem ;
            display: none;

            @include breakpoint(m) {
                width: 75%;
                font-size: .7rem;
                display: block;
            }

            // @include breakpoint(l) {
            //     font-size: .7rem;
            //     width: 65%;
            // }

            // @include breakpoint(xl) {
            //     width: 50%;
            // }

            // @include breakpoint(xl) {
            //     font-size: .8rem;
            // }

            @include bph-max(980px) {
                display: none;
            }
        }
    }

    &__videos {
        position: absolute;
        top: $nav-height;
        bottom: 0;
        left: 0;
        right: 0;

        &-container {
            position: relative;
            height: 80%;
            overflow: hidden;
            padding: 0 20px;

            @include breakpoint-upto(l) {
                width: calc(100% - 2rem);
                margin: 0 auto;
            }

            @include bph-max(1000px) {
                height: 75%;
            }
        }
    }

    &__video {
        position: absolute;
        display: block;
        pointer-events: none;

        &-top {
            object-fit: cover;
            width: calc(100% - 40px);
            height: 100%;
            object-position: 50% 100%;

            @include breakpoint-upto(l) {
                width: 100%;
                margin-left: -20px;
            }
        }

        &-bottom {
            top: 80%;
            height: 20%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 0;
            z-index: 1;

            @include bph-max(1000px) {
                top: 75%;
            }
        }
    }

    &__sprite-top,
    &__sprite-bottom {
        pointer-events: none;
        position: absolute;
        z-index: 2;
        width: 16%;
        overflow: hidden;
        left: 50%;
        transform: translateX(-50%);

        @include breakpoint(m) {
            width: 9%;
        }

        @include breakpoint(xl) {
            width: 7.5%;
        }

        &-image {
            max-width: none;
            height: 100%;
            width: 6300%;
        }
    }

    &__sprite-top {
        bottom: 0;

        &-image {
            .intro:not(.out) & {
                animation: spritesheet-top 2.52s steps(63) infinite;

                @keyframes spritesheet-top {
                    100% {
                        transform: translateX(-100%);
                   }
                }
            }
        }
    }

    &__sprite-bottom {
        &-container {
            position: absolute;
            width: 100%;
            top: 80%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;

            @include bph-max(1000px) {
                top: 75%;
            }
        }

        &-image {
            .intro:not(.out) & {
                animation: spritesheet-bottom 2.52s steps(63) infinite;

                @keyframes spritesheet-bottom {
                    0% {
                        transform: translateX(0);
                    }

                    100% {
                        transform: translateX(-100%);
                    }
                }
            }
        }
    }

    &__buttons {
        .button {
            display: block;
            margin: 0 auto;
            white-space: nowrap;
            min-width: 135px;

            @include breakpoint(m) {
                display: inline-block;
                margin: .5rem 1rem;
            }
        }
    }

    &__foreground {
        transform: translateY(-100%);
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        &-image {
            max-width: 50%;

            &-left {
                height: 16px;
            }
        }
    }

    &__logo {
        overflow: hidden;
        width: 100%;
        max-width: 608px;
        margin: -1rem auto 1rem;
        will-change: transform;

        @include bph-max(900px) {
            margin: -2rem auto 1rem;
        }

        &-image {
            max-width: none;
            width: 4400%;
            backface-visibility: visible;
            will-change: transform;
        }
    }
}
