.menu {
    display: inline-block;
    font-family: $font-family-sans-serif;
    font-weight: 100;

    &__items {
        @include list-unstyled;
    }

    &__item {
        font-size: .6rem;
        margin: 0 5px;
        display: inline-block;

        @include breakpoint(m) {
            font-size: .7rem;
            margin: 0 25px;
        }

        &:first-child,
        &:last-child {
            @include breakpoint-upto(m) {
                display: none;
            }
        }
    }

    &__link {
        @include letter-spacing(xxl);
        position: relative;
        text-decoration: none;
        color: $text-color;
        text-transform: uppercase;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            bottom: -7px;
            left: 50%;
            margin-left: -14px;
            width: 28px;
            height: 1px;
            background: currentColor;
            will-change: transform;
            transform: scaleX(0);
            transition: transform .2s ease-in-out;
        }

        &.active {
            color: $primary-color;

            &::after {
                transform: scaleX(1);
            }

            .scrolling & {
                color: $text-color;

                &::after {
                    transform: scaleX(0);
                }
            }
        }

        @include hover {
            &::after {
                transform: scaleX(1);
            }
        }
    }
}
