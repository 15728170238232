$footer-height-m: 6rem;
$footer-height-l: 10rem;

.episodes__footer {
    position: absolute;
    // top: 0;
    // left: 0;
    height: 6rem;
    background: color($color-gray, light);
    color: color($color-blue, medium);
    width: 100%;
    max-width: 1655px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    // margin-left: 50%;
    // transform: translateX(-50%);
    z-index: z-index(episodes-footer);


    // @include breakpoint-upto(l) {
    //     display: none;
    // }

    .episodes.before-out & {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .episodes.out & {
        position: relative;
    }

    &-wrapper {
        height: 0;
        width: 100%;
        position: relative;

        @include breakpoint(l) {
            height: 9rem;
        }
    }

    @include breakpoint(l) {
        height: 10rem;
        color: color($color-gray);
        margin-top: -1rem;
        background: transparent;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            bottom: 0;
            z-index: -2;
            left: 0;
            top: 1rem;
            background: color($color-white);
        }

        // &::after {
        //     height: 1rem;
        //     background: color($color-gray, light);
        // }
    }

    // &.before-out {
    //     position: fixed;
    //     bottom: 0;
    //     left: 0;
    //
    //     @include breakpoint(l) {
    //         height: 11rem;
    //     }
    // }

    &-channels {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        width: 100%;
        padding: .5rem;

        @include breakpoint(l) {
            width: 500px;
            padding: 1rem;
        }
    }

    &-text {
        margin: .5rem 0 -.5rem;

        @include breakpoint(l) {
            margin: 1.5rem 0 0;
        }
    }

    &-channel {
        width: 3rem;

        @include breakpoint(l) {
            padding: 0;
        }

        @include hover {
            opacity: .5;
        }
    }

    &-more {
        color: color($color-blue);
        text-decoration: underline;
        cursor: pointer;
        margin-top: .75rem;
        // line-height: 6rem;

        @include breakpoint(l) {
            margin-top: .7rem;
        }
    }

    // &-image {
    //     display: none;
    //
    //     @include breakpoint(l) {
    //         display: block;
    //         position: absolute;
    //         object-fit: cover;
    //         object-position: 50% 100%;
    //         width: 100%;
    //         height: 10rem;
    //         z-index: -1;
    //     }
    // }
}
