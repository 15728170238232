.episodes {
    &__intro {
        position: relative;
        z-index: z-index(episodes-intro);
        padding: 1.5rem 2rem 1rem;

        @include breakpoint(l) {
            text-align: center;
            width: col(8);
            margin: 0 auto;
        }
    }

    &__heading {
        @include letter-spacing(xxl);

        @include breakpoint(l) {
            font-size: 3rem;
        }
    }

    &__body {
        position: relative;

        &::after {
            content: '';
            position: absolute;
            left: .8rem;
            width: 34px;
            height: 2px;
            background: color($color-blue, dark);
            margin-top: .5rem;
            transform: translateX(-50%);

            @include breakpoint(l) {
                width: 76px;
                left: 50%;
            }
        }
    }

    &__list {
        position: relative;
        padding-bottom: 6rem;

        @include breakpoint(l) {
            padding-bottom: 9rem;
        }
    }
}
