.share {
    display: flex;
    flex-wrap: wrap;
    margin-top: 3rem;
    justify-content: center;

    @include breakpoint(l) {
        flex-wrap: nowrap;
    }

    &__item {
        width: calc(50% - .5rem);
        margin: 1rem 0;
        text-align: center;
        display: flex;

        @include breakpoint(l) {
            width: calc(33.33% - .5rem);
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        background: color($color-gray, light);
        color: color($color-blue);
        margin: 0 auto;

        @include hover {
            background: color($color-gray, medium);
        }
    }

    &__icon {
        width: 2.5rem;
        height: 2.5rem;
        margin: 1.25rem;
    }

    &__title {
        margin: 0 auto .75rem;
        font-style: italic;
        text-decoration: none;
        color: color($color-gray);
        font-size: .65rem;
    }
}
