* {
    &,
    &::before,
    &::after {
        box-sizing: border-box;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    display: block;
    background: color($color-gray, light);
    overflow: hidden;
}

.scrollable {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

figure {
    margin: 0;
}

img {
    display: block;
    max-width: 100%;
}

a {
    color: color($color-blue);
}

h1,
h2,
h3,
h4,
h5,
p {
    margin-top: 0;
    margin-bottom: .6rem;

    @include breakpoint(l) {
        margin-bottom: 1rem;
    }
}
