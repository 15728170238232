.channels {
    margin-top: 3rem;
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: 1fr 1fr;

    @include breakpoint(m) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .channel {
        text-align: center;
        background: color($color-gray, light);
        display: flex;

        &__link {
            display: block;
            text-decoration: none;
            width: 100%;
        }

        &__icon {
            width: 4rem;
            margin: 1rem auto 0;
        }

        &__title {
            margin: 0 auto .75rem;
            font-style: italic;
            text-decoration: none;
            color: color($color-gray);
            font-size: .65rem;
        }

        @include hover {
            background: color($color-gray, medium);

            .channel__title {
                color: color($color-black);
            }
        }
    }
}
