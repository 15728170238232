$icon-sizes: (
    xs: 9px,
    sm: 12px,
    md: 15px,
    lg: 20px,
    xl: 30px,
    xxl: 50px,
);

@mixin icon($size: md) {
    $icon-size: map-get($icon-sizes, $size);

    width: $icon-size;
    height: $icon-size;
    min-width: $icon-size;
    min-height: $icon-size;
}

.icon {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    svg {
        display: inline-block;
        vertical-align: top;
        fill: currentColor;
    }
}

.icon-xs {
    @include icon(xs);
}

.icon-sm {
    @include icon(sm);
}

.icon-md {
    @include icon(md);
}

.icon-lg {
    @include icon(lg);
}

.icon-xl {
    @include icon(xl);
}

.icon-xxl {
    @include icon(xxl);
}
