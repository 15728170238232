.container {
    @include container;
}

.locked {
    overflow: hidden;
}

.main {
    max-width: 1655px;
    margin: 0 auto;
    background: color($color-white);
    overflow-x: hidden;

    @include breakpoint(l) {
        padding-top: $nav-height / 2;
    }
}
