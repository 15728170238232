@mixin no-touch {
    html:not(.touchevents) & {
        @content;
    }
}

@mixin touch {
    .touchevents & {
        @content;
    }
}

@mixin hover {
    @include no-touch {
        &:hover {
            @content;
        }
    }
}
