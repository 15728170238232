$intro-bg-color: #e5937d;

.button {
    display: inline-block;
    padding: 0;
    border: 0;
    background: transparent;
    font-family: $font-family-serif;
    font-size: .7rem;
    font-style: italic;
    text-decoration: none;
    color: $primary-color;
    cursor: pointer;

    &:focus {
        border: 0;
        box-shadow: none;
        outline: none;
    }

    &__icon {
        @include icon(sm);
        vertical-align: middle;
        margin-right: 14px;

        &.round {
            @include icon(xl);

            svg {
                @include icon(sm);
                margin-left: 9px;
                margin-top: 9px;
            }

            &::before {
                content: '';
                position: absolute;
                border: 2px solid currentColor;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
    }

    &__text {
        vertical-align: middle;
    }

    &__pill {
        background: color($color-blue);
        color: color($color-white);
        padding: 0 1rem .2rem;
        border-radius: 1rem;
        line-height: 2.25;
        transition: background .2s ease-in-out;

        &-icon {
            @include icon(sm);
            margin-left: 8px;
        }

        @include hover {
            background: color($color-blue, dark);
        }
    }

    &__modal {
        @include hover {
            &.button-icon-round {
                color: color($color-blue, dark);
            }
        }

        &-icon {
            @include icon(xs);
            margin-right: 8px;
        }
    }

    &__round {
        position: relative;
        border-radius: 50%;
        width: 75px;
        height: 75px;
        text-align: center;
        color: color($color-white);
        word-spacing: 75px;
        text-transform: uppercase;
        font-family: $font-family-monospace;
        font-style: normal;
        font-size: .7rem;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border: 2px solid color($color-white);
        overflow: hidden;
        z-index: 1;

        &::before {
            content: '';
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: color($color-white);
            transform-origin: bottom;
            transform: scaleY(0);
            transition: transform .4s ease-in-out;
        }

        &:focus {
            border: 2px solid color($color-white);
        }

        @include breakpoint(l) {
            width: 115px;
            height: 115px;
            font-size: 1.1rem;
        }

        @include hover {
            color: $intro-bg-color;

            &::before {
                transform: scaleY(1);
            }
        }

        &.fade {
            border-color: transparent;
            color: $intro-bg-color;

            &::before {
                transform: scaleY(1);
            }
        }
    }
}
