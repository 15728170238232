$nav-height-sm: 3.125rem;
$nav-height: 3.75rem;

// colors
$color-blue: (
    base: #20a7de,
    medium: #005b82,
    dark: #003145,
    transparent: rgba(0, 49, 69, .9),
);

$color-gray: (
    base: #7c7f7d,
    medium: #d3d3d3,
    light: #f2f4f5,
);

$color-black: (
    base: #000,
);

$color-white: (
    base: #fff,
    transparent: rgba(255, 255, 255, .9),
);

$primary-color: map-fetch($color-blue, base);
$text-color: map-fetch($color-gray, base);

// Media queries breakpoints
$breakpoints: (
    s: 480px,
    m: 768px,
    l: 1024px,
    xl: 1366px,
    xxl: 1655px
);

$containers: (
    s: 440px,
    m: 720px,
    l: 1020px,
    xl: 1188px,
    xxl: 1188px,
);

// letter spacing
$letter-spacing: (
    xs: -30,
    s: 10,
    m: 20,
    l: 30,
    xl: 40,
    xxl: 50,
);

$z-indices: episodes-intro, episode-overlay, episode-headings, episode-foreground, episodes-footer, sidenav, intro, nav, menu, language, modal;
